import React, { useCallback, useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";
import { HeroLayout } from "../components/hero-layout";
import { Link } from "gatsby";
import {
  FeatureCard,
  FeatureCardCopy,
  FeatureCardImage,
  FeatureCardTitle,
} from "../components/feature-card";
import { BeforeAfterReveal } from "../components/before-after-reveal";

const Integration = ({ text, logoSrc, wip }) => (
  <div className={"integrations-container__integration"}>
    <div className="integrations-container__integration-logo-wrapper">
      <img className="integrations-container__integration-logo" src={logoSrc} />
    </div>
    <h3 className="integrations-container__integration-text">{text}</h3>
    {wip && (
      <p className="integrations-container__wip">
        ({typeof wip === "string" ? wip : "coming soon"})
      </p>
    )}
  </div>
);

const Testimonial = ({
  name,
  jobTitle,
  linkedin,
  companyLogoURL,
  companySite,
  companyName,
  text,
  idx = 0,
}) => (
  <div className={`testimonial testimonial--${idx}`}>
    <div className="testimonial__header">
      <div className="testimonial__header-content">
        <h3
          className="testimonial__name"
          style={{ marginBottom: rhythm(1 / 8) }}
        >
          {linkedin ? (
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="testimonial__name-link"
            >
              {name}

              {/*I am hiding linkedIn icon, because with it feels like we are affiliated with linkedIn */}
              {/*{" "}*/}
              {/*<img*/}
              {/*  className="testimonial__linkedin"*/}
              {/*  src={require("../../../images/LI-In-Bug.png")}*/}
              {/*/>*/}
            </a>
          ) : (
            <>{name}</>
          )}
        </h3>
        <p className="testimonial__job-title">{jobTitle}</p>
      </div>
      <a
        href={companySite}
        target="_blank"
        rel="noopener noreferrer"
        className="testimonial__site"
      >
        <img
          className="testimonials__company-logo"
          alt={companyName}
          src={companyLogoURL}
        />
      </a>
    </div>
    <p
      className="testimonial__text"
      style={{ marginTop: rhythm(0.33), marginBottom: 0 }}
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  </div>
);

const TestimonialScrollableContainer = ({ children }) => {
  const containerRef = useRef(null);

  return (
    <>
      <div className="testimonials-container__testimonials-wrapper">
        <div
          className="testimonials-container__testimonials"
          style={{ marginTop: rhythm(0), marginBottom: rhythm(0) }}
          ref={containerRef}
        >
          {children}
        </div>
      </div>
    </>
  );
};

class Index extends React.Component {
  state = {
    productName: "Remeet",
  };

  componentDidMount() {}

  render() {
    return (
      <Layout withMaxWidth={false}>
        <SEO />
        {/* <HeroLayout withStaticBackground={true}>
          <h1 className="title" style={{ marginBottom: rhythm(1 / 2) }}>
            Topic-first meetings for teams
          </h1>
          <h2
            className="subtitle"
            style={{ marginTop: 0, marginBottom: rhythm(1) }}
          >
            Rewire your distributed team’s communication channels for maximum
            productivity with a fundamentally new approach to meetings
          </h2>

          <RequestAccessBtn style={{ marginRight: rhythm(1 / 2) }} />

          <Link
            to="/principles/topic-first-meeting-principle"
            className="whitepaper-main-link mdc-button mdc-button--text"
          >
            What’s topic-first?
          </Link>
        </HeroLayout> */}
        <BeforeAfterReveal />

        <section
          className="testimonials-container-wrapper gray-container straight"
          style={{
            paddingTop: rhythm(2.5),
            paddingBottom: rhythm(7.5), // because next container will overlap it
            overflow: "hidden", // make sure scrollable testimonials are inside the container
          }}
        >
          <div className="testimonials-container">
            <TestimonialScrollableContainer>
              <Testimonial
                companyName="Knowledge Futures Group"
                companyLogoURL={require("../../assets/testimonials/kfg.svg")}
                companySite="https://www.knowledgefutures.org/about"
                name="Gabe Stein"
                linkedin="https://www.linkedin.com/in/gabestein/"
                jobTitle="Head of Product & Operations"
                idx={0}
                text="“Remeet has profoundly changed how we work, and how we think about work, allowing our team to be more productive, more creative, and get more done in less time. We recently <b>switched to 4-day, 8-hour workweeks</b>, which would not have been possible if we hadn’t already adopted Remeet.”" /** Even if you’re not ready to go that far yet, almost every team should try Remeet. Give it a shot. Trust me. */
                //text="“Remeet is a new addition to our process, we use it to condense stand-ups, check-ins, and single-topic syncs — into a <b>single hour each morning</b>, freeing up the rest of the day for more productive work. Very roughly the equivalent of a morning standup — although in practice, it’s an ultra-productive super-standup that helps everyone <b>get energized for the day ahead</b>.”"
              />
              <Testimonial
                companyName="FinCompare"
                companyLogoURL={require("../../assets/testimonials/fincompare.png")}
                companySite="https://fincompare.de/ueber-fincompare"
                name="Christoph Strasen"
                linkedin="https://www.linkedin.com/in/christophstrasen/"
                jobTitle="Chief Technology Officer"
                idx={1}
                text="“Our makers appreciate how Remeet <b>empowers decentralized collaboration</b>. We generate meeting outcomes faster with <b>fewer people and less time</b> which allows everyone to focus and get more stuff done. I love it and its Slack integration!”"
              />
              <Testimonial
                companyName="Frontastic, Germany"
                companyLogoURL={require("../../assets/testimonials/Frontastic-Logo-Gradient.svg")}
                companySite="https://www.frontastic.cloud/about/"
                name="Tobias Schlitt"
                linkedin="https://www.linkedin.com/in/tobiasschlitt/"
                jobTitle="VP of Engineering"
                idx={2}
                text="“Remeet helps us to <b>condense scattered synchronization points</b> of an asynchronous company into well-dosed, focused, short meeting slots. It allows us to better divide between focus times and exchange times. It helped us to <b>make meetings shorter</b> and more well-documented.”"
              />
              <Testimonial
                companyName="Ventura TRAVEL"
                companyLogoURL={require("../../assets/testimonials/ventura-travel.svg")}
                companySite="https://www.venturatravel.org/about-us"
                name="Erick Feijoo"
                linkedin="https://www.linkedin.com/in/eifeijoo/"
                jobTitle="Chief Operating Officer"
                idx={3}
                text="“Remeet has allowed us to fully embrace one of our core values: <b>FOCUS</b>. Gone are the days of complicated scheduling and scattered meetings throughout the day. Meetings with a clear purpose and reliable frequency are now the norm — and a true <b>game changer</b> especially for a digital company like ours!”"
              />
              <Testimonial
                companyName="Wolters Kluwer"
                companyLogoURL={require("../../assets/testimonials/Wolters_Kluwer_Logo.svg")}
                companySite="https://www.wolterskluwer.com/en/about-us"
                name="Andrey Selitsky"
                linkedin="https://www.linkedin.com/in/andrey-selitsky-a5a6aa2/"
                jobTitle="Engineering Manager"
                idx={4}
                text="“We have been using Remeet for more than a year now, and <b>we love it!</b> In our distributed team of 50 people, we allocated two hours per day for meetings, and we rarely use them all — most often, we are good with just one. As a result, <b>everyone has good 6+ hours per day for deep work</b>. ”"
              />
            </TestimonialScrollableContainer>
          </div>
        </section>

        <section
          className="benefits-page white-container"
          style={{
            marginTop: rhythm(-5),
            marginBottom: rhythm(0), // ends with dark sub section
            paddingTop: rhythm(2.5),
          }}
        >
          <div className="benefits-page__benefits-list">
            <div style={{ position: "relative" }}>
              <div className="meetter-square-purple"></div>
              <div className="meetter-square-blue"></div>
              <div className="meetter-square-green"></div>
              <div
                className={"benefits-page__benefits-list-subsection"}
                style={{ zIndex: 20 }}
              >
                <FeatureCard
                  idx={1}
                  chatBubbleText={
                    "“But that means mean more time wasted by scheduling, right?”"
                  }
                  style={{ marginTop: rhythm(-1) }}
                >
                  <FeatureCardTitle>
                    One hour in Remeet{" "}
                    <span style={{ fontWeight: 400, fontSize: "34px" }}>=</span>{" "}
                    Two+ hours in your current meeting app
                  </FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      Forget about large team meetings with unrelated agenda
                      topics. Instead, we’ll schedule a separate{" "}
                      <b>mini-meeting for every topic</b> so everyone can{" "}
                      <b>join only relevant topics</b>. This saves a ton of time
                      waiting/multitasking.
                    </p>
                    <p>
                      As most topics won’t require the same people, we’ll
                      schedule many of them <b>in parallel</b>.
                    </p>
                    <p>
                      We’ll also automatically move people from one topic to
                      another so that all the meetings always{" "}
                      <b>start and end on time</b>.
                    </p>
                    <p>
                      As a result, all meetings will be <b>very focused</b> and{" "}
                      <b>super fast</b> so your team will discuss{" "}
                      <b>at least twice as many topics</b> in the same amount of
                      time.
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <video
                      style={{ maxWidth: "100%" }}
                      autoPlay
                      playsInline
                      loop
                      muted
                      src="/static/flowing.mp4?v2"
                    ></video>
                  </FeatureCardImage>
                </FeatureCard>

                <FeatureCard
                  idx={2}
                  chatBubbleText="“That’s great! Our engineering team will love that!”"
                >
                  <FeatureCardTitle>
                    Up to 35 meeting-free hours every single week
                  </FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      We`ll schedule topics close to each other to{" "}
                      <b>save your energy</b> on jumping between meetings and
                      tasks (with 5-minute breaks if you prefer).
                    </p>
                    <p>
                      As a team, you`ll need to allocate some hours on your
                      calendar where we can schedule all the topics. We call
                      those hours{" "}
                      <b style={{ color: "darkorange" }}>sync hours</b>.
                    </p>
                    <p>
                      If there are more topics than can fit into the sync hours,
                      we’ll <b>automatically postpone</b> less important topics
                      to the following week.
                    </p>
                    <p>
                      With Remeet,{" "}
                      <b>meetings can no longer compete with work.</b>
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <img
                      src={require("../../../images/main/maker-manager.png")}
                      style={{ transform: "scale(1.15)" }}
                      alt="With Remeet makers have a lot more time free of meetings"
                    />
                  </FeatureCardImage>
                </FeatureCard>

                <FeatureCard
                  idx={3}
                  chatBubbleText={
                    "“But for large teams, wouldn't these hours quickly get overbooked? Seems chaotic...”"
                  }
                >
                  <FeatureCardTitle>
                    Fully autonomous scheduling (for real)
                  </FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      When a topic arises, figure out who you need to talk to
                      and how long the conversation needs to be, and we’ll{" "}
                      <b>schedule and reschedule it automatically.</b>
                    </p>
                    <p>
                      <b>No need to choose a time or confirm availability.</b>{" "}
                      When someone takes a day off, they’ll just need to block
                      that day on their Google/Office365 calendar, and we’ll
                      automatically rearrange all the topics.
                    </p>
                    <p>
                      You can also <b>prioritize topics</b> to get more
                      important discussions scheduled sooner. We’ll
                      automatically postpone some of the less urgent topics to
                      accommodate more urgent.
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <video
                      style={{ maxWidth: "100%" }}
                      autoPlay
                      playsInline
                      loop
                      muted
                      src="/static/discuss-more-in-less-time.mp4"
                    ></video>
                  </FeatureCardImage>
                </FeatureCard>

                <FeatureCard
                  idx={4}
                  chatBubbleText="“I feel like this may solve all my problems! What else can Remeet do?”"
                >
                  <FeatureCardTitle>Prepare asynchronously</FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      In the case of complex discussions, a big chunk of time is
                      usually wasted to get everyone on the same page, as you
                      can’t expect people to find time to read any materials in
                      advance.
                    </p>
                    <p>
                      With Remeet, when you schedule a topic, you can provide
                      all the necessary context and materials for preparation.
                      Then you <b>specify which participants need to prepare</b>{" "}
                      before the topic gets scheduled.{" "}
                    </p>
                    <p>
                      <b>
                        Once everyone has indicated they’re ready, we’ll
                        schedule the topic automatically.
                      </b>
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <video
                      style={{ maxWidth: "100%" }}
                      autoPlay
                      playsInline
                      loop
                      muted
                      src="/static/everyone-comes-prepared.mp4"
                    ></video>
                  </FeatureCardImage>
                </FeatureCard>
              </div>
            </div>

            <div
              className={"benefits-page__benefits-list-dark"}
              style={{
                paddingTop: rhythm(2.5),
                paddingBottom: rhythm(2.5 + 5),
              }}
            >
              <div style={{ position: "relative" }}>
                <div className="meetter-square-pink"></div>
              </div>
              <div className={"benefits-page__benefits-list-subsection"}>
                <FeatureCard
                  idx={5}
                  chatBubbleText="“Oh! So the more we use it, the more efficient our meetings are?”"
                >
                  <FeatureCardTitle>
                    Meeting app that speeds you up
                  </FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      The vast majority of your single-topic meetings will be
                      within a 5 to 15-minute time range. You have to trust our
                      customers and us on this one.
                    </p>
                    <p>
                      It works through (a) making everyone well aware of time
                      constraints, (b) automatically transitioning everyone from
                      one topic to another, and (c) encouraging the organizer to
                      schedule a follow-up in two clicks when the group is
                      running out of time (instead of forcing everyone to stay
                      longer).
                    </p>
                    <p>
                      At first, this may feel uncomfortable for some people, but
                      it’s like the first month in a gym. The more they use
                      Remeet, the more efficient their meetings become.
                    </p>
                    <p>
                      Also, integrated note-taking, automatic recordings,
                      transcriptions and meeting summaries can help reduce the
                      number of participants.
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <img
                      style={{ boxShadow: "0 3px 3px rgba(0,0,0,0.2)" }}
                      src={require("../../../images/main/remeet-flow.png")}
                      alt="Remeet video experience"
                    />
                  </FeatureCardImage>
                </FeatureCard>

                <FeatureCard
                  idx={6}
                  chatBubbleText="“That makes so much sense!”"
                >
                  <FeatureCardTitle>
                    Optimize the use of very limited timezone overlap
                  </FeatureCardTitle>
                  <FeatureCardCopy>
                    <p>
                      All the convenient time on the intersection of timezones
                      occupied by unmovable weekly meetings?
                    </p>
                    <p>
                      With {this.state.productName}, you can create sync hours
                      at the intersection of timezones and get the maximum value
                      out of the most precious hours.
                    </p>
                  </FeatureCardCopy>
                  <FeatureCardImage>
                    <img
                      src={require("../../../images/main/global-team-dark-2.png")}
                      style={{ transform: "scale(1.15)" }}
                      alt="Remeet helps to schedule meetings across timezones"
                    />
                  </FeatureCardImage>
                </FeatureCard>
              </div>
            </div>
          </div>
        </section>

        <section
          className="before-after pink-container"
          style={{
            paddingTop: rhythm(2.5),
            paddingBottom: rhythm(2.5),
            maxWidth: "none",
            marginTop: rhythm(-5),
          }}
        >
          <div
            className="before-after__text"
            style={{ marginBottom: rhythm(2) }}
          >
            <h2 className="before-after__text-title">
              We’ll help you onboard your entire team in a matter of days,
              regardless of its size
            </h2>
            <p className="before-after__text-p">
              <a
                href="/getstarted/"
                className="mdc-button mdc-button--unelevated"
              >
                Begin the onboarding
              </a>
            </p>
          </div>
          {/* <div className="before-after__container">
            <div className="before-after__before">
              <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
                <figcaption>Your meeting before</figcaption>
                <div className="before-after__after-image__container">
                  <video
                    style={{ maxWidth: "100%" }}
                    autoPlay
                    playsInline
                    loop
                    muted
                    src="/static/flowing.mp4"
                  ></video>
                  <div className="before-after__before-meetter-icon"></div>
                </div>
              </figure>
            </div>
            <div className="before-after__after">
              <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
                <figcaption>Your meeting after</figcaption>
                <div className="before-after__after-image__container">
                  <video
                    style={{ maxWidth: "100%" }}
                    autoPlay
                    playsInline
                    loop
                    muted
                    src="/static/flowing.mp4"
                  ></video>
                  <div className="before-after__after-meetter-icon"></div>
                </div>
              </figure>
            </div>
          </div> */}
        </section>

        {/* <section
          className="video-container"
          style={{ paddingTop: rhythm(2.5), paddingBottom: rhythm(2.5) }}
        >
          <h2
            className="video-container__title"
            style={{ marginTop: rhythm(2.5), marginBottom: rhythm(2.5) }}
          >
            Suffering from meeting fatigue? There’s a cure.
          </h2>
          <div className="video-wrapper">
            <div className="video-card">
              <iframe
                src="https://player.vimeo.com/video/525745034?dnt=1&amp;portrait=0&amp;byline=0&amp;title=0&amp;autoplay=0;&amp;loop=1&amp;autopause=0"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                portrait="false"
                title={"Remeet explanation video"}
              ></iframe>
            </div>
          </div>
        </section> */}

        <section
          className="integrations-container-wrapper"
          style={{
            marginTop: rhythm(5),
            marginBottom: rhythm(5),
          }}
        >
          <div className="integrations-container">
            <h2
              className="integrations-container__title"
              id="integrations"
              style={{
                marginTop: rhythm(2.5),
                marginBottom: rhythm(2.5),

                maxWidth: 720,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Deep integration with team calendars and chats
            </h2>

            <div
              className="integrations-container__integrations"
              style={{
                marginTop: rhythm(2.5),
                marginBottom: rhythm(2.5 / 2),
              }}
            >
              <Integration
                text={"Google Calendar"}
                logoSrc={require("../../assets/integrations/google_calendar.png")}
              />
              <Integration
                text={"Office 365 Calendar"}
                logoSrc={require("../../assets/integrations/microsoft_calendar.svg")}
              />

              <Integration
                text={"Slack"}
                logoSrc={require("../../assets/integrations/slack.svg")}
              />

              <Integration
                text={"Mattermost"}
                logoSrc={require("../../assets/integrations/mattermost.svg")}
              />

              <Integration
                text={"Google Chat"}
                logoSrc={require("../../assets/integrations/google_chat.png")}
              />
              {/*<Integration*/}
              {/*  text={"Zoom"}*/}
              {/*  logoSrc={require("../../assets/integrations/zoom.svg")}*/}
              {/*  wip={true}*/}
              {/*/>*/}
              <Integration
                text={"Microsoft Teams"}
                logoSrc={require("../../assets/integrations/teams.svg")}
                wip={true}
              />
              {/*<Integration*/}
              {/*  text={"Google Meet"}*/}
              {/*  logoSrc={require("../../assets/integrations/google_meet.png")}*/}
              {/*  wip={true}*/}
              {/*/>*/}
            </div>
            <div style={{ textAlign: "center" }}>
              <a
                href="/getstarted/"
                className="request-a-demo mdc-button mdc-button--unelevated"
                style={{ marginRight: rhythm(1 / 2) }}
              >
                Get Started
              </a>
              {/* <a
                href="mailto:support@remeet.com?subject=Question about integration"
                className="whitepaper-main-link mdc-button mdc-button--text"
              >
                Ask a question
              </a> */}
            </div>
          </div>
        </section>
        <section
          className="compliances-container-wrapper"
          style={{
            marginTop: rhythm(2.5),
            marginBottom: rhythm(0),
          }}
        >
          <div
            className="compliances-container"
            style={{
              marginTop: rhythm(2.5),
              marginBottom: rhythm(0),
              paddingBottom: rhythm(1),

              maxWidth: 720,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h2 className="compliances-container__title" id="compliance">
              <a href="/security/">SOC 2 Compliant</a>
            </h2>

            <div className="compliances-container__compliances">
              <a href="/security/">
                <img
                  className="compliances-container__compliance-logo"
                  src={require("../../assets/compliance/21972-312_SOC_NonCPA.png")}
                />
              </a>

              <a href="/security/">
                <img
                  className="compliances-container__compliance-logo"
                  src={require("../../assets/compliance/drata-soc2-light.png")}
                />
              </a>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
