import "./before-after.scss";
import { rhythm } from "../utils/typography";
// import { Link } from "gatsby";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
// import { useViewportScroll, useTransform, motion } from "framer-motion";
// import { useForceUpdate } from "../utils/use-force-update";
// import { ProductNameContext } from "../context";

export const BeforeAfterReveal = () => {
  return (
    <section
      className="before-after"
      style={{ paddingTop: 64, paddingBottom: 10 }}
    >
      <div className="before-after__text" style={{ marginBottom: rhythm(2) }}>
        <h2 className="before-after__text-title">
          {/* Get more done in less time with bottom-up topic-first meetings */}
          {/* Reduce time wasted by meetings */}
          Incredibly fast and efficient meetings
        </h2>
        <p className="before-after__text-p">
          Discuss more topics in less time, consistently get up to 35 meeting-free
          hours a week, eliminate all the waste associated with manual scheduling
          {/* and discuss hundreds of topics in a single hour */}
          {/* Remeet is a high speed, high energy, high transparency, and high output video calls. */}
          {/* Remeet to meetings is what Slack to Email. Smaller, faster,
          mtransforming meetings the way Slack transofrmed Email. */}
          {/* Remeets is the only meeting app that makes your team meetings small,
          short, and highly efficient. */}
          {/* Implement topic-first meetings with a transparent topics backlog, an
          autonomous meeting scheduler, and multi-dimensional meeting rooms. */}
          {/* Remeet reverses the chronic flow of top-down standing meetings with
          transparent company-wide topics backlog, autonomous meeting
          scheduler, and multi-dimensional meeting rooms. */}
          <br /> <br />
          <a
            href="/getstarted/"
            className="mdc-button mdc-button--unelevated main-cta"
          >
            GET STARTED
            {/* <span class="text-long"> Try for free</span>
            <span class="text-short"> Try for free</span> */}
          </a>
          <a
            href="/whitepaper"
            className="whitepaper-main-link mdc-button mdc-button--text"
          >
            <span class="text-long"> Read whitepaper</span>
            <span class="text-short"> Whitepaper</span>
          </a>
        </p>
      </div>
      {/* <div className="before-after__container">
        <div className="before-after__before">
          <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
            <figcaption>Before</figcaption>
            <div className="before-after__after-image__container">
              <img
                className="no-mg-bottom before-after__before-image"
                width={568}
                height={320}
                src={require("../../../images/main/week-before-optimized.png")}
                alt="Without Remeet your calendar is really busy"
              />
              <div className="before-after__before-meetter-icon"></div>
            </div>
          </figure>
        </div>
        <div className="before-after__after">
          <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
            <figcaption>After</figcaption>
            <div className="before-after__after-image__container">
              <img
                className="no-mg-bottom before-after__after-image"
                width={568}
                height={320}
                src={require("../../../images/main/week-after-optimized.png")}
                alt="With Remeet your calendar is a lot more organized"
              />
              <div className="before-after__after-meetter-icon"></div>
            </div>
          </figure>
        </div>
      </div> */}
    </section>
  );
};

// export const BeforeAfterReveal = () => {
//   const { scrollY } = useViewportScroll();
//   const [height, setHeight] = useState(0);
//   const [elementTop, setElementTop] = useState(0);
//   const forceUpdate = useForceUpdate();
//   const ref = useRef(null);
//
//   // amount: 0 - just starting to be visible
//   // amount: .5 - reached center
//   const reveal = (amount) => Math.max(elementTop - height * (1 - amount), 0);
//
//   const y = useTransform(scrollY, [reveal(1), reveal(0.33)], [height / 5, 0], {
//     clamp: true,
//   });
//   const opacity = useTransform(
//     scrollY,
//     [reveal(1), reveal(0.25), reveal(0.33)],
//     [0, 0.25, 1],
//     {
//       clamp: true,
//     }
//   );
//
//   const iconOpacity = useTransform(
//     scrollY,
//     [reveal(0.33), reveal(0.5)],
//     [0, 1],
//     {
//       clamp: true,
//     }
//   );
//
//   const xIconTransition = useTransform(iconOpacity, [0, 1], [0, 50], {
//     clamp: true,
//   });
//   const yIconTransition = useTransform(iconOpacity, [0, 1], [0, -50], {
//     clamp: true,
//   });
//
//   useLayoutEffect(() => {
//     const element = ref.current;
//     setElementTop(
//       Math.round(element.getBoundingClientRect().top + window.pageYOffset)
//     );
//     setHeight(window.innerHeight);
//   }, []);
//
//   // workaround for https://github.com/meetter/meetter/issues/302
//   useEffect(() => {
//     if (!elementTop) return;
//     try {
//       document.dispatchEvent(new CustomEvent("scroll"));
//     } catch (e) {}
//     forceUpdate();
//   }, [elementTop]);
//
//   const productName = React.useContext(ProductNameContext);
//
//   return (
//     <section
//       className="before-after"
//       style={{ paddingTop: 64, paddingBottom: rhythm(2.5) }}
//     >
//       <div className="before-after__text" style={{ marginBottom: rhythm(2) }}>
//         <h2 className="before-after__text-title">
//           Optimize meetings for your entire company
//         </h2>
//         <p className="before-after__text-p">
//           Maximize focus time and prevent calendar congestion with <b>fully autonomous meeting scheduler</b>.
//           Condense 30-minute meetings into 10 minutes with <b>habit-improving meeting experience</b>.
//           <br /> <br />
//           <a
//             href="/getstarted/"
//             className="mdc-button mdc-button--unelevated"
//           >
//             Get Started
//           </a>
//         </p>
//       </div>
//       <div className="before-after__container">
//         <div className="before-after__before">
//           <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
//             <figcaption>Before</figcaption>
//             <div className="before-after__after-image__container">
//               <img
//                 ref={ref}
//                 style={{ visibility: "hidden" }}
//                 aria-hidden="true"
//                 className="no-mg-bottom"
//                 src={require("../../../images/main/week-before-optimized.png")}
//                 alt=""
//               />
//               <motion.img
//                 className="no-mg-bottom before-after__before-image"
//                 src={require("../../../images/main/week-before-optimized.png")}
//                 alt="Without Remeet your calendar is really busy"
//                 style={{ y, opacity }}
//               />
//               <motion.div
//                 className="before-after__before-meetter-icon"
//                 style={{
//                   opacity: iconOpacity,
//                   x: yIconTransition,
//                   y: xIconTransition,
//                 }}
//               ></motion.div>
//             </div>
//           </figure>
//         </div>
//         <div className="before-after__after">
//           <figure style={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
//             <figcaption>After</figcaption>
//             <div className="before-after__after-image__container">
//               <img
//                 style={{ visibility: "hidden" }}
//                 aria-hidden="true"
//                 className="no-mg-bottom"
//                 src={require("../../../images/main/week-after-optimized.png")}
//                 alt=""
//               />
//               <motion.img
//                 className="no-mg-bottom before-after__after-image"
//                 src={require("../../../images/main/week-after-optimized.png")}
//                 alt="With Remeet your calendar is a lot more organized"
//                 style={{ y, opacity }}
//               />
//               <motion.div
//                 className="before-after__after-meetter-icon"
//                 style={{
//                   opacity: iconOpacity,
//                   x: xIconTransition,
//                   y: yIconTransition,
//                 }}
//               ></motion.div>
//             </div>
//           </figure>
//         </div>
//       </div>
//     </section>
//   );
// };
